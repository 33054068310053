/* Add these CSS styles to your stylesheet or component */
.map-card-container {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-card {
    flex: 0 0 50%;
    /* Set the width of each card to 50% */
    padding: 10px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    max-width: 30%;
}

.offcanvas {
    border-radius: 5px;
    background-color: #efefef;
    visibility: visible;
}

.modal-content {
    background-color: #efefef;
}

.create-jobs-right-card {
    background-color: #efefef;
}

.create-jobs-left-card {
    background-color: #efefef;
}

.current-jobs-right-card {
    background-color: #efefef;
}

.current-jobs-left-card {
    background-color: #efefef;
}

.profile-status-box-card {
    background-color: #efefef;
}

.profile-status-box-card-header {
    background-color: #efefef;
}

/*===================== CREAT ORDER IMAGE VIEW =====================*/
.img-wraps {
    position: relative;
    display: inline-block;

    font-size: 0;
}

.img-wraps .closes {
    position: absolute;
    top: 3px;
    right: 4px;
    z-index: 100;
    background-color: #FFF;
    padding: 4px 3px;

    color: #000;
    font-weight: bold;
    cursor: pointer;

    text-align: center;
    font-size: 15px;
    line-height: 9px;
    border-radius: 50%;
    border: 1px solid red;
}

.img-wraps:hover .closes {
    opacity: 1;
}

/*===================== CREAT ORDER IMAGE VIEW =====================*/

/*===================== progress Bar Css =====================*/
.indeterminate-progress-bar {
    /* Color */
    background-color: #d1d5db;

    /* Rounded border */
    border-radius: 9999px;

    /* Size */
    height: 0.5rem;

    position: relative;
    overflow: hidden;
}

.indeterminate-progress-bar__progress {
    /* Color */
    background-color: #32383e;

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 50%;

    /* Move the bar infinitely */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }

    to {
        left: 100%;
    }
}

/*===================== progress Bar Css =====================*/

/*===================== circle Css =====================*/
.block {
    /* border: 1px solid red; */
    text-align: center;
    vertical-align: middle;
}

.circle {
    background: black;
    border-radius: 200px;
    color: white;
    height: 60px;
    font-weight: bold;
    width: 60px;
    display: table;
    /* margin: 20px auto; */
}

.circle p {
    vertical-align: middle;
    display: table-cell;
}

/*===================== circle Css =====================*/

/*===================== scroll-container Css =====================*/
div.scroll-container {
    background-color: #efefef;
    overflow: auto;
    white-space: nowrap;
    padding: 5px;
    border-radius: 5px;
}

div.scroll-container img {
    padding: 5px;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
}

/*===================== scroll-container Css =====================*/

/*===================== FTM Request Notification CSS =====================*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');

.ftm-request-notification-body {
    display: grid;
    place-items: center;
    width: 100%;
    /* height: 100vh; */
    /* background-image: linear-gradient(to bottom right, rgb(10, 153, 209), rgb(5, 76, 122)); */
    text-align: center;
    font-size: 16px;
}

.ftm-request-notification-body-icon {
    color: #212529;
    font-size: 90px;
    /* padding: 30px 0px; */
}

.b1 {
    background-color: #212529;
    /* box-shadow: 0px 4px #212529; */
    font-size: 17px;
}

.r3 {
    color: #c1c1c1;
    font-weight: 500;
}

a,
a:hover {
    text-decoration: none;
}

/*===================== FTM Request Notification CSS =====================*/

/*===================== Direct Chat CSS =====================*/
#frame {
    width: 95%;
    min-width: 360px;
    max-width: 1000px;
    height: 110%;
    min-height: 300px;
    max-height: 720px;
    /* background: #E6EAEA; */
}

@media screen and (max-width: 360px) {
    #frame {
        width: 100%;
        height: 100vh;

    }
}

#frame #sidepanel {
    float: left;
    min-width: 280px;
    max-width: 340px;
    width: 40%;
    height: 100%;
    background: #2c3e50;
    color: #f5f5f5;
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel {
        width: 58px;
        min-width: 58px;
    }
}

#frame #sidepanel #profile {
    width: 80%;
    margin: 25px auto;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile {
        width: 100%;
        margin: 0 auto;
        padding: 5px 0 0 0;
        background: #32465a;
    }
}

#frame #sidepanel #profile.expanded .wrap {
    height: 210px;
    line-height: initial;
}

#frame #sidepanel #profile.expanded .wrap p {
    margin-top: 20px;
}

#frame #sidepanel #profile.expanded .wrap i.expand-button {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

#frame #sidepanel #profile .wrap {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    -moz-transition: 0.3s height ease;
    -o-transition: 0.3s height ease;
    -webkit-transition: 0.3s height ease;
    transition: 0.3s height ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap {
        height: 55px;
    }
}

#frame #sidepanel #profile .wrap img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #e74c3c;
    height: auto;
    float: left;
    cursor: pointer;
    -moz-transition: 0.3s border ease;
    -o-transition: 0.3s border ease;
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap img {
        width: 40px;
        margin-left: 4px;
    }
}

#frame #sidepanel #profile .wrap img.online {
    border: 2px solid #2ecc71;
}

#frame #sidepanel #profile .wrap img.away {
    border: 2px solid #f1c40f;
}

#frame #sidepanel #profile .wrap img.busy {
    border: 2px solid #e74c3c;
}

#frame #sidepanel #profile .wrap img.offline {
    border: 2px solid #95a5a6;
}

#frame #sidepanel #profile .wrap p {
    float: left;
    margin-left: 15px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap p {
        display: none;
    }
}

#frame #sidepanel #profile .wrap i.expand-button {
    float: right;
    margin-top: 23px;
    font-size: 0.8em;
    cursor: pointer;
    color: #2b2a2a;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap i.expand-button {
        display: none;
    }
}

#frame #sidepanel #profile .wrap #status-options {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    margin: 70px 0 0 0;
    border-radius: 6px;
    z-index: 99;
    line-height: initial;
    background: #2b2a2a;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options {
        width: 58px;
        margin-top: 57px;
    }
}

#frame #sidepanel #profile .wrap #status-options.active {
    opacity: 1;
    visibility: visible;
    margin: 75px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options.active {
        margin-top: 62px;
    }
}

#frame #sidepanel #profile .wrap #status-options:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #2b2a2a;
    margin: -8px 0 0 24px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options:before {
        margin-left: 23px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul {
    overflow: hidden;
    border-radius: 6px;
}

#frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 30px 18px;
    display: block;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li {
        padding: 15px 0 35px 22px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li:hover {
    background: #496886;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
        width: 14px;
        height: 14px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px;
    background: transparent;
    border-radius: 50%;
    z-index: 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
        height: 18px;
        width: 18px;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li p {
    padding-left: 12px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li p {
        display: none;
    }
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
    background: #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
    border: 1px solid #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
    background: #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
    border: 1px solid #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
    background: #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
    border: 1px solid #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
    background: #95a5a6;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
    border: 1px solid #95a5a6;
}

#frame #sidepanel #profile .wrap #expanded {
    padding: 100px 0 0 0;
    display: block;
    line-height: initial !important;
}

#frame #sidepanel #profile .wrap #expanded label {
    float: left;
    clear: both;
    margin: 0 8px 5px 0;
    padding: 5px 0;
}

#frame #sidepanel #profile .wrap #expanded input {
    border: none;
    margin-bottom: 6px;
    background: #32465a;
    border-radius: 3px;
    color: #f5f5f5;
    padding: 7px;
    width: calc(100% - 43px);
}

#frame #sidepanel #profile .wrap #expanded input:focus {
    outline: none;
    background: #2b2a2a;
}

#frame #sidepanel #search {
    border-top: 1px solid #32465a;
    border-bottom: 1px solid #32465a;
    font-weight: 300;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #search {
        display: none;
    }
}

#frame #sidepanel #search label {
    position: absolute;
    margin: 10px 0 0 20px;
}

#frame #sidepanel #search input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    padding: 10px 0 10px 46px;
    width: calc(100% - 25px);
    border: none;
    background: #32465a;
    color: #f5f5f5;
}

#frame #sidepanel #search input:focus {
    outline: none;
    background: #2b2a2a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #search input::-moz-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
    color: #f5f5f5;
}

#frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts {
        height: calc(100% - 149px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #frame #sidepanel #contacts::-webkit-scrollbar {
        display: none;
    }
}

#frame #sidepanel #contacts.expanded {
    height: calc(100% - 334px);
}

#frame #sidepanel #contacts::-webkit-scrollbar {
    width: 8px;
    background: #2c3e50;
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    background-color: #243140;
}

#frame #sidepanel #contacts ul li.contact {
    position: relative;
    padding: 10px 0 15px 0;
    font-size: 0.9em;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact {
        padding: 6px 0 46px 8px;
    }
}

#frame #sidepanel #contacts ul li.contact:hover {
    background: #32465a;
}

#frame #sidepanel #contacts ul li.contact.active {
    background: #32465a;
    border-right: 5px solid #2b2a2a;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
    border: 2px solid #32465a !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
    width: 88%;
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap {
        width: 100%;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #95a5a6;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
    background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
    background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
    background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap img {
        margin-right: 0px;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta {
    padding: 5px 0 0 0;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap .meta {
        display: none;
    }
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
    font-weight: 600;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
    margin: 5px 0 0 0;
    padding: 0 0 1px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
}

#frame #sidepanel #bottom-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
}

#frame #sidepanel #bottom-bar button {
    float: left;
    border: none;
    width: 50%;
    padding: 10px 0;
    background: #32465a;
    color: #f5f5f5;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button {
        float: none;
        width: 100%;
        padding: 15px 0;
    }
}

#frame #sidepanel #bottom-bar button:focus {
    outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: 1px solid #2c3e50;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button:nth-child(1) {
        border-right: none;
        border-bottom: 1px solid #2c3e50;
    }
}

#frame #sidepanel #bottom-bar button:hover {
    background: #2b2a2a;
}

#frame #sidepanel #bottom-bar button i {
    margin-right: 3px;
    font-size: 1em;
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button i {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button span {
        display: none;
    }
}

#frame .content {
    float: right;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 735px) {
    #frame .content {
        width: calc(100% - 58px);
        min-width: 300px !important;
    }
}

/* @media screen and (min-width: 900px) {
  #frame .content {
    width: calc(100% - 340px);
  }
} */
#frame .content .contact-profile {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #f5f5f5;
}

#frame .content .contact-profile img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin: 9px 12px 0 9px;
}

#frame .content .contact-profile p {
    float: left;
}

#frame .content .contact-profile .social-media {
    float: right;
}

#frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
    color: #2b2a2a;
}

#frame .content .messages {
    height: auto;
    min-height: calc(100% - 93px);
    max-height: calc(100% - 93px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 735px) {
    #frame .content .messages {
        max-height: calc(100% - 105px);
    }
}

#frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul {
    padding-left: 0rem;
}

#frame .content .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
}

#frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
}

#frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
}

#frame .content .messages ul li.sent p {
    background: #2b2a2a;
    color: #f5f5f5;
}

#frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
}

#frame .content .messages ul li.replies p {
    background: #76e776;
    color: #000000;
    float: right;
}

#frame .content .messages ul li img {
    width: 30px;
    /* border-radius: 50%; */
    float: left;
}

#frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 285px;
    line-height: 130%;
}

/* @media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
} */
#frame-input .content .message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
}

#frame-input .content .message-input .wrap {
    position: relative;
}

#frame-input .content .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 90px);
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: #32465a;
}

@media screen and (max-width: 735px) {
    #frame-input .content .message-input .wrap input {
        padding: 15px 32px 16px 8px;
    }
}

#frame-input .content .message-input .wrap input:focus {
    outline: none;
}

#frame-input .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #2b2a2a;
    opacity: .5;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame-input .content .message-input .wrap .attachment {
        margin-top: 17px;
        right: 65px;
    }
}

#frame-input .content .message-input .wrap .attachment:hover {
    opacity: 1;
}

#frame-input .content .message-input .wrap button {
    float: right;
    border: none;
    width: 50px;
    padding: 12px 0;
    cursor: pointer;
    background: #2b2a2a;
    color: #f5f5f5;
}

@media screen and (max-width: 735px) {
    #frame-input .content .message-input .wrap button {
        padding: 16px 0;
    }
}

#frame-input .content .message-input .wrap button:hover {
    background: #2b2a2a;
}

#frame-input .content .message-input .wrap button:focus {
    outline: none;
}

/*===================== Direct Chat CSS =====================*/

/*===================== CHAT VIEW =========================*/
:root {
    --msger-bg: #fff;
    /* --border: 2px solid #ddd; */
    --left-msg-bg: #ececec;
    --right-msg-bg: #579ffb;
}

/* *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  } */

.chat-body {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-image: var(--body-bg);
    /* width: 95%; */
}

.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    /* margin: 25px 10px; */
    /* height: calc(100% - 50px); */
    height: 100%;
    border: var(--border);
    border-radius: 5px;
    /* background: var(--msger-bg); */
    /* box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2); */
}

.msger-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: var(--border);
    background: #eee;
    color: #666;
}

.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.left-msg .msg-img {
    width: 50px;
    height: 30px;
}

.right-msg .msg-img {
    width: 30px;
    height: 30px;
}

.msg-bubble {
    max-width: 900px;
    padding: 15px;
    border-radius: 15px;
    background: var(--left-msg-bg);
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.msger-inputarea {
    display: flex;
    padding: 10px;
    border-top: var(--border);
    background: #eee;
}

.msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}

.msger-input {
    flex: 1;
    background: #ddd;
}

.msger-send-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
}

.msger-send-btn:hover {
    background: rgb(0, 180, 50);
}

.alert {
    cursor: pointer;
}

/*===================== CHAT VIEW =========================*/

.css-1gv0vcd-MuiSlider-track {
    color: #eea113;
}

#customDatePickerCalendar div .MuiTextField-root {
    width: 100%;
}

.MuiBottomNavigation-root .Mui-selected {
    color: #55AE59
}

.MuiBottomNavigation-root .Mui-selected svg {
    color: #55AE59
}

.fc-event {
    padding: 5px;
}

.error-alert-custom-icon {
    border: none;
}  

.required-asterisk {
    color: red;
    margin-left: 2px; /* Optional: adds a little space between label and asterisk */
}